<template>
  <div>
    <CCard>
      <CCardHeader>
        <CIcon name="cil-sort-descending" />Sondagem
      </CCardHeader>
      <CCardBody>
        <loader :loading="requisicaoAberta"></loader>
        <CDataTable
          class="table-outline"
          hover
          :items="sondagens"
          :fields="CamposTabela"
          head-color="light"
          sorter
          :loading="carregandoSondagem"
        >
          <td slot="camadas" slot-scope="{item}">{{item.nspt.length}}</td>
          <td slot="acoes" class="text-center" slot-scope="{item}">
            <CButtonGroup>
              <CButton color="danger" @click="removerSondagem(item)">
                <CIcon name="cil-trash" />
              </CButton>
              <CButton color="info" @click="abrirSondagem(item)">
                <CIcon name="cil-folder-open" />
              </CButton>
            </CButtonGroup>
          </td>

          <template slot="no-items-view">
            <div class="text-center my-5">
              <h2 v-if="!requisicaoAberta">Nenhuma sondagem cadastrada!!!</h2>
            </div>
          </template>
        </CDataTable>
      </CCardBody>
      <CCardFooter>
        <CButton color="success" size="sm" class="float-right" @click="novaSondagem">
          <CIcon name="cil-notes"></CIcon>&nbsp;Nova Sondagem
        </CButton>
      </CCardFooter>
    </CCard>
    <CModal
      title="Modal title"
      :show.sync="formularioCadastroSondagem"
      size="lg"
      addContentClasses="card"
      :closeOnBackdrop="false"
      ref="formularioSondagem"
    >
      <template slot="header-wrapper">
        <CCardHeader>
          <CIcon name="cil-notes" />&nbsp;Cadastro de Sondagem
          <CButtonClose
            @click="formularioCadastroSondagem = false"
            buttonClasses="text-muted close"
          />
        </CCardHeader>
      </template>
      <template slot="body-wrapper">
        <CCardBody>
          <SBFormularioSondagem :sondagem="sondagemAtiva" @sondagemSalva="sondagemSalvoSucesso"></SBFormularioSondagem>
        </CCardBody>
      </template>
      <template slot="footer-wrapper">
        <CCardFooter>
          <CButton color="success" size="sm" class="float-right" @click="salvarSondagem">
            <CIcon name="cil-save"></CIcon>&nbsp;Salvar
          </CButton>
        </CCardFooter>
      </template>
    </CModal>
  </div>
</template>

<script>
import Axios from "axios";
import SBFormularioSondagem from "./FormularioSondagem";
import Meta from "./../../shared/meta-dados";
export default {
  name: "Sondagem",
  components: {
    SBFormularioSondagem
  },
  data() {
    return {
      carregandoSondagem: false,
      formularioCadastroSondagem: false,
      sondagens: [],
      sondagemAtiva: {
        identificacao: "",
        datasondagem: null,
        y: null,
        x: null,
        nivelagua: null,
        observacao: "",
        nspt: []
      },
      enviarFormulario: false,
      requisicaoAberta: false,
      CamposTabela: [
        { key: "idLinha", sortable: true, label: "#", _classes: "text-center" },
        {
          key: "identificacao",
          label: "Identifacação",
          _classes: "text-center",
          sorter: true
        },
        {
          key: "datasondagemhumanizada",
          label: "Data da sondagem",
          sorter: false
        },
        { key: "nivelagua", label: "Nível da água", sorter: false },
        { key: "camadas", label: "Número de Camadas", sorter: false },
        { key: "acoes", label: "Ações", sorter: false }
      ]
    };
  },
  methods: {
    sondagemSalvoSucesso() {
      this.$eventoHub.$emit("adicionarMensagemAviso", {
        mensagem: "A sondagem foi salva!",
        classe: "bg-success"
      });
      this.formularioCadastroSondagem = false;
      this.obtenhaSondagem();
    },
    sondagemSalvoErro() {
      this.$eventoHub.$emit("adicionarMensagemAviso", {
        mensagem: "Não foi possível salvar a sondagem!",
        classe: "bg-danger"
      });
    },
    obtenhaSondagem() {
      this.requisicaoAberta = true;
      let idProjeto = this.$route.params.projetoID;
      Axios.get("/projeto/" + idProjeto + "/sondagem")
        .then(response => {
          this.sondagens = this.processarSondagens(response.data.retorno);
        })
        .catch(error => {
          this.$router.push({
            name: "Home"
          });
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    },
    processarSondagens(sondagens) {
      if (!typeof sondagens === "object") {
        return [];
      }
      return sondagens.map((sondagem, index) => {
        let data = new Date(sondagem.datasondagem);
        let offset = data.getTimezoneOffset();
        data.setUTCMinutes(offset);
        sondagem.datasondagemhumanizada = data.toLocaleDateString();
        sondagem.idLinha = index + 1;
        return sondagem;
      });
    },
    salvarSondagem() {
      this.$eventoHub.$emit("salvarSondagem", "");
    },
    removerSondagem(sondagem) {
      let idProjeto = this.$route.params.projetoID;
      this.$swal({
        title: "Atenção",
        icon: "warning",
        text: Meta.obtenhaMensagem(1003, "pt-br"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          this.requisicaoAberta = true;
          Axios.delete("/projeto/" + idProjeto + "/sondagem/" + sondagem.id)
            .then(resposta => {
              if (resposta.status) {
                this.$eventoHub.$emit("adicionarMensagemAviso", {
                  mensagem: "A sondagem foi excluída!",
                  classe: "bg-success"
                });
              }
              this.obtenhaSondagem();
            })
            .catch(error => {})
            .finally(() => {
              this.requisicaoAberta = false;
            });
        }
      });
    },
    novaSondagem() {
      this.$eventoHub.$emit("sondagem", {
        identificacao: "",
        datasondagem: null,
        y: null,
        x: null,
        nivelagua: null,
        observacao: "",
        nspt: [],
        id: false
      });

      this.formularioCadastroSondagem = true;
    },
    abrirSondagem(sondagem) {
      this.$eventoHub.$emit("sondagem", sondagem);
      this.formularioCadastroSondagem = true;
    }
  },
  watch: {
    formularioCadastroSondagem(novoValor, velhoValor) {
      let tempoEspera;
      if (novoValor) {
        tempoEspera = setTimeout(() => {
          this.$eventoHub.$emit("shown", this.$refs["formularioSondagem"]);
        }, 450);
      } else {
        this.obtenhaSondagem();
      }
    }
  },
  mounted: function() {
    this.obtenhaSondagem();
  }
};
</script>
