<template>
  <div>
    <loader :loading="requisicaoAberta"></loader>
    <CForm>
      <CRow>
        <CCol ref="controleAltura" sm="7">
          <CRow>
            <CCol sm="6">
              <CInput
                label="Identificação"
                :value.sync="$v.sondagemManipulado.identificacao.$model"
                :isValid="verificarCampo('identificacao', 'sondagemManipulado')"
                invalidFeedback="É necessário informar uma identificação para Sondagem"
              />
            </CCol>
            <CCol sm="6">
              <CInput
                type="date"
                label="Data da sondagem"
                :value.sync="sondagemManipulado.datasondagem"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="4">
              <CInput
                label="Localização X"
                type="number"
                :value.sync="$v.sondagemManipulado.x.$model"
                :isValid="verificarCampo('x', 'sondagemManipulado')"
                invalidFeedback="A localização deve ser numérica"
              />
            </CCol>
            <CCol sm="4">
              <CInput
                label="Localização Y"
                type="number"
                :value.sync="$v.sondagemManipulado.y.$model"
                :isValid="verificarCampo('y', 'sondagemManipulado')"
                invalidFeedback="A localização deve ser numérica"
              />
            </CCol>
            <CCol sm="4">
              <CInput
                label="Nível da Água"
                type="number"
                :value.sync="$v.sondagemManipulado.nivelagua.$model"
                :isValid="verificarCampo('nivelagua','sondagemManipulado')"
                invalidFeedback="O nível da água deve ser numérico"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <CTextarea label="Observação" :value.sync="sondagemManipulado.observacao" />
            </CCol>
          </CRow>
          <hr />
          <CRow>
            <CCol sm="12">
              <fieldset class="form-group">
                <div role="group">
                  <h6>Dados de Ensaio SPT</h6>
                  <div class="input-group mt-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">NSPT</span>
                    </div>
                    <input
                      type="number"
                      :class="['form-control', {'is-invalid' : !verificarCampo('nspt','dadosCamada')}]"
                      v-model="$v.dadosCamada.nspt.$model"
                      min="0"
                      ref="campo-nspt"
                      @keyup.enter="adicionarCamadas"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">Solo</span>
                    </div>
                    <select
                      :class="['form-control custom-select', {'is-invalid' : !verificarCampo('solo','dadosCamada')}]"
                      v-model="$v.dadosCamada.solo.$model"
                    >
                      <option v-for="(solo, key) in solos" :key="key" :value="key">{{solo}}</option>
                    </select>
                    <div class="input-group-append">
                      <CButton color="success" @click="adicionarCamadas">
                        <CIcon name="cil-library-add" height="16" />
                      </CButton>
                    </div>
                  </div>
                  <div
                    :class="['invalid-feedback',{'d-block' : !verificarCampo('nspt','dadosCamada')}]"
                  >Informe um valor válido para o NSPT</div>
                  <div
                    :class="['invalid-feedback',{'d-block' : !verificarCampo('solo','dadosCamada')}]"
                  >É necessário escolher um solo na lista.</div>
                </div>
              </fieldset>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <CDataTable
                small
                :fields="camposTabelaSondagem"
                :items="sondagemManipulado.nspt"
                :responsive="false"
              >
                <td slot="solo" slot-scope="{item}">{{solos[item.solo]}}</td>
                <td slot="acoes" slot-scope="{item}">
                  <CButtonGroup size="sm">
                    <CButton
                      color="info"
                      :disabled="item.camada==1"
                      @click="subirCamadaSondagem(item.camada)"
                    >
                      <CIcon name="cil-arrow-top"></CIcon>
                    </CButton>
                    <CButton color="danger" @click="removerCamadaSondagem(item.camada)">
                      <CIcon name="cil-trash"></CIcon>
                    </CButton>
                    <CButton
                      color="info"
                      :disabled="sondagemManipulado.nspt.length===item.camada"
                      @click="descerCamadaSondagem(item.camada)"
                    >
                      <CIcon name="cil-arrow-bottom"></CIcon>
                    </CButton>
                  </CButtonGroup>
                </td>
              </CDataTable>
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="5">
          <h5 class="text-center">Perfil da sondagem</h5>
          <PerfilSondagem
            :sondagemSPT="sondagemManipulado.nspt"
            :altura="alturaPerfilSondagem | inteiro"
            :nivel-agua="sondagemManipulado.nivelagua | decimal"
            class="perfil-sondagem"
          ></PerfilSondagem>
        </CCol>
      </CRow>
    </CForm>
  </div>
</template>

<script>
import { required, decimal, between, minValue } from "vuelidate/lib/validators";
import Axios from "axios";
import Loader from "./../Componentes/loader";
import PerfilSondagem from "./PerfilSondagem";
import Meta from "./../../shared/meta-dados";
import mixin from "./../../shared/mixin";

export default {
  name: "SBFormularioSondagem",
  components: { PerfilSondagem, Loader },
  mixins: [mixin],
  props: {},
  data() {
    return {
      alturaPerfilSondagem: 50,
      camposTabelaSondagem: [
        { key: "camada" },
        { key: "nspt", label: "NSPT" },
        { key: "solo", label: "Solo" },
        { key: "acoes", label: "Ações" }
      ],
      dadosCamada: {
        nspt: "",
        solo: 0
      },
      sondagemManipulado: {
        identificacao: "",
        datasondagem: null,
        y: null,
        x: null,
        nivelagua: null,
        observacao: "",
        nspt: [[]]
      },
      nspt: [[]],
      solos: Meta.obtenhaSolos,
      requisicaoAberta: false
    };
  },
  validations: {
    sondagemManipulado: {
      identificacao: { required },
      x: { decimal },
      y: { decimal },
      nivelagua: { decimal }
    },
    dadosCamada: {
      nspt: { required, decimal, minValue: minValue(1) },
      solo: { required, between: between(1, 15) }
    }
  },
  methods: {
    carregarSondagemFormulario(sondagem) {
      let sondagemImportado = Object.assign({}, sondagem);
      this.sondagemManipulado = Object.assign(
        {},
        this.sondagemManipulado,
        sondagemImportado
      );
      this.$v.sondagemManipulado.$reset();
      this.$v.dadosCamada.$reset();
    },
    adicionarCamadas() {
      this.$v.dadosCamada.$touch();
      if (this.$v.dadosCamada.$invalid) {
        return;
      }
      let camada = this.sondagemManipulado.nspt.length + 1;
      this.sondagemManipulado.nspt.push({
        camada: camada,
        nspt: parseInt(this.dadosCamada.nspt),
        solo: parseInt(this.dadosCamada.solo)
      });
      this.dadosCamada.nspt = null;
      this.$refs["campo-nspt"].focus();
      this.alturaPerfilSondagem = this.calcularAlturaPerfil() + 114;
    },
    calcularAlturaPerfil() {
      let alturaTotal = 0;
      for (let i = 0; i < this.$refs["controleAltura"].children.length; i++) {
        alturaTotal += this.$refs["controleAltura"].children[i].scrollHeight;
      }
      return alturaTotal;
    },
    ordenarCamadas() {
      this.sondagemManipulado.nspt.forEach((element, key) => {
        this.sondagemManipulado.nspt[key].camada = key + 1;
      });
    },
    removerCamadaSondagem(camada) {
      let index = camada - 1;
      this.sondagemManipulado.nspt.splice(index, 1);
      this.ordenarCamadas();
      this.alturaPerfilSondagem = this.calcularAlturaPerfil();
    },

    descerCamadaSondagem(camada) {
      let index = camada - 1;
      let spt = this.sondagemManipulado.nspt[index];
      this.sondagemManipulado.nspt.splice(index, 1);
      let nspt = [];
      this.sondagemManipulado.nspt.forEach((elemento, key) => {
        nspt.push(elemento);
        if (key == index) nspt.push(spt);
      });
      this.sondagemManipulado.nspt = nspt;
      this.ordenarCamadas();
    },

    subirCamadaSondagem(camada) {
      let index = camada - 1;
      let spt = this.sondagemManipulado.nspt[index];
      this.sondagemManipulado.nspt.splice(index, 1);
      let nspt = [];
      this.sondagemManipulado.nspt.forEach((elemento, key) => {
        if (key + 1 == index) nspt.push(spt);
        nspt.push(elemento);
      });
      this.sondagemManipulado.nspt = nspt;
      this.ordenarCamadas();
    },
    salvarSondagem() {
      this.$v.sondagemManipulado.$touch();
      if (this.$v.sondagemManipulado.$invalid) {
        return;
      }
      let idProjeto = this.$route.params.projetoID;
      let url = this.sondagemManipulado.id
        ? "/projeto/" + idProjeto + "/sondagem/" + this.sondagemManipulado.id
        : "/projeto/" + idProjeto + "/sondagem/";
      this.requisicaoAberta = true;

      Axios.post(url, this.sondagemManipulado)
        .then(resposta => {
          this.$emit("sondagemSalva", resposta.data.retorno);
        })
        .catch(error => {
          this.$swal(
            "Erro",
            this.gerarMenssagemDeErro(error.response.data.retorno)
          );
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    },
    formularioAberto(modal) {
      if (modal.$el.children[0].classList.contains("show")) {
        this.alturaPerfilSondagem = this.calcularAlturaPerfil() + 114;
        this.$eventoHub.$emit("atualizar-altura", this.alturaPerfilSondagem);
      }
    }
  },
  watch: {},
  created() {
    this.$eventoHub.$on("salvarSondagem", this.salvarSondagem);
    this.$eventoHub.$on("sondagem", this.carregarSondagemFormulario);
    this.$eventoHub.$on("shown", this.formularioAberto);
  },
  destroyed() {
    this.$eventoHub.$off("salvarSondagem", this.salvarSondagem);
    this.$eventoHub.$off("sondagem", this.carregarSondagemFormulario);
    this.$eventoHub.$off("shown", this.formularioAberto);
  }
};
</script>
<style scoped>
.modal-content > .card-body > div > form > .row > .col-sm-7 {
  height: 70vh;
  overflow: auto;
}
</style>
